

































import { Vue, Component, Watch } from 'vue-property-decorator';
import EventSelect from '@/components/EventSelect.vue';
import Log from './components/Log.vue';
import ManuallyRun from './components/ManuallyRun.vue';
import Density from './components/Density.vue';
import { rootModule } from '@/store';
import { storeModule, storeNS } from './store';
import { API } from '@/types';
import { Socket } from 'vue-socket.io-extended';

@Component({
  components: {
    EventSelect,
    Log,
    ManuallyRun,
    Density,
  },
})
export default class extends Vue {
  @storeNS.State readonly event!: API.Events.GET | null;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Socket('eventModified')
  socketEventModified(newVal: API.Events.GET | null, oldVal: API.Events.GET | null): void {
    if (oldVal?.id === this.selectedEvent) {
      if (!newVal) storeModule.setProperty({ key: 'event', val: null });
      else storeModule.setProperty({ key: 'event', val: { ...this.event, ...newVal } });
    } else if (!oldVal && newVal?.id === this.selectedEvent) {
      storeModule.setProperty({ key: 'event', val: { ...this.event, ...newVal } });
    }
  }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    await storeModule.loadEventAPIData(this.selectedEvent);
    await storeModule.loadAuthorisedChannelsAPIData();
  }
}
