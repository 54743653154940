





























import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import { rootModule } from '@/store';
import { storeNS } from '../store';

dayjs.extend(relativeTime);

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  @Ref('DataTable') dataTable!: DataTable;
  commercialLog: API.Commercials.Logs.GET[] = []; // Only stores the current page of commercials!
  tableOptions = { page: 1, itemsPerPage: 10 };
  options = {
    apiName: 'commercialLog',
    socketName: 'commercialLogged',
    idStr: 'id',
    headers: [
      { text: 'Timestamp', value: 'timestamp' },
      { text: 'Length', value: 'length', width: '1%', align: 'end' },
      { text: 'Manual', value: 'manual', width: '1%', align: 'end' },
      // { text: 'Channel IDs', value: 'channelIds' },
      // { text: 'Event ID', value: 'eventId' },
      { text: 'For Event?', value: 'forEvent', width: '1%', align: 'end' },
    ],
    hideActions: true,
    hideAdd: true,
  };
  dayjs = dayjs;

  // Force a reload if the store based commercial log changes.
  @storeNS.State((s) => s.commercialLog) readonly storeLog!: API.Commercials.Logs.GET[];
  @Watch('storeLog')
  onStoreLogChange(): void {
    this.dataTable.loadAPIData();
  }

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }
}
