var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"py-3",style:({ 'border-bottom': _vm.seperator })},[_vm._v(" Commercial Log ")]),_c('data-table',{ref:"DataTable",attrs:{"items":_vm.commercialLog,"tableOptions":_vm.tableOptions,"options":_vm.options,"selectedEvent":_vm.selectedEvent},on:{"update:items":function($event){_vm.commercialLog=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.timestamp).format('MMM D, YYYY h:mm A'))+" ")]}},{key:"item.length",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.length)+"s ")]}},{key:"item.manual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.manual ? 'Yes' : 'No')+" ")]}},{key:"item.forEvent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.eventId !== null ? 'Yes' : 'No')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }