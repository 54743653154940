import { apiGETMulti, apiGETSingle } from '@/api';
import { store } from '@/store';
import { API } from '@/types';
import { updateAPIArr } from '@/util';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const moduleName = 'admin_commercials';
if (store.hasModule(moduleName)) store.unregisterModule(moduleName);

@Module({ dynamic: true, store, namespaced: true, name: moduleName })
export class StoreModule extends VuexModule {
  event: API.Events.GET | null = null;
  authorisedChannels: API.AuthorisedChannels.Channels.GET[] = [];
  commercialLog: API.Commercials.Logs.GET[] = [];

  // Helper mutation for actions to set things in the state.
  // Typings could be improved.
  @Mutation
  setProperty({ key, val }: { key: string, val: unknown }): void {
    Vue.set(this, key, val);
  }

  @Mutation
  pushToCommercialLog(val: API.Commercials.Logs.GET): void {
    this.commercialLog.unshift(val);
    if (this.commercialLog.length > 20) this.commercialLog.length = 20;
  }

  @Action
  async loadEventAPIData(id: number | null): Promise<void> {
    let data: API.Events.GET | null = null;
    if (id !== null) ({ data } = await apiGETSingle('events', id));
    this.setProperty({ key: 'event', val: data });
  }

  @Action
  async loadAuthorisedChannelsAPIData(): Promise<void> {
    const { data } = await apiGETMulti('authorisedChannels', {}, true);
    this.setProperty({ key: 'authorisedChannels', val: data });
  }

  @Action
  async loadCommercialLogAPIData(): Promise<void> {
    const { data } = await apiGETMulti('commercialLog', {
      max: 20, // TODO: Actually paginate if needed.
    });
    this.setProperty({ key: 'commercialLog', val: data });
  }

  @Mutation
  // eslint-disable-next-line max-len
  SOCKET_AUTHORISEDCHANNELMODIFIED([newVal, oldVal]: (API.AuthorisedChannels.Channels.GET | null)[]): void {
    Vue.set(this, 'authorisedChannels', updateAPIArr(this.authorisedChannels, newVal, oldVal));
  }

  @Mutation
  SOCKET_COMMERCIALLOGGED([val]: (API.Commercials.Logs.GET | null)[]): void {
    if (val) this.pushToCommercialLog(val);
  }
}

export const storeModule = getModule(StoreModule, store);
export const storeNS = namespace(moduleName);
