
































import { Vue, Component } from 'vue-property-decorator';
import clone from 'clone';
import { API } from '@/types';
import { Socket } from 'vue-socket.io-extended';
import { apiGETMulti } from '@/api';
import { storeModule, storeNS } from '../store';

@Component
export default class extends Vue {
  @storeNS.State readonly event!: API.Events.GET | null;
  @storeNS.State readonly authorisedChannels!: API.AuthorisedChannels.Channels.GET[];
  @storeNS.State readonly commercialLog!: API.Commercials.Logs.GET[];
  streamData: API.AuthorisedChannels.StreamData.GET[] = [];
  currentTime = Date.now();

  get channel(): API.AuthorisedChannels.Channels.GET | null {
    // If no main channel, use first data for now!
    if (!this.event?.mainChannelId) return this.authorisedChannels[0];
    return this.authorisedChannels.find((c) => c.id === this.event?.mainChannelId) ?? null;
  }

  get chanStreamData(): API.AuthorisedChannels.StreamData.GET['data'] | null {
    return this.streamData.find((s) => s.id === this.channel?.id)?.data ?? null;
  }

  // TODO: Handle errors!
  async loadStreamDataAPIData(): Promise<void> {
    Vue.set(this, 'streamData', (await apiGETMulti('streamData', {}, true)).data);
  }

  @Socket('authorisedChannelStreamDataModified')
  socketStreamDataModified(val: API.AuthorisedChannels.StreamData.GET): void {
    const index = this.streamData.findIndex((i) => i.id === val.id);
    if (index >= 0) Vue.set(this.streamData, index, val);
    else this.streamData.push(val);
  }

  // Logs for specific channel sorted newest first.
  get logsForChan(): API.Commercials.Logs.GET[] {
    if (!this.channel) return [];
    return clone(this.commercialLog)
      .filter((c) => this.channel && c.channelIds.includes(this.channel.id))
      .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp));
  }

  get lastHourLogs(): API.Commercials.Logs.GET[] {
    const hourAgo = this.currentTime - (60 * 60 * 1000);
    return this.logsForChan.filter((c) => Date.parse(c.timestamp) >= hourAgo);
  }

  get lastHourAmount(): number {
    return this.lastHourLogs.reduce((prev, curr) => prev + curr.length, 0);
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }

  async created(): Promise<void> {
    window.setInterval(() => { this.currentTime = Date.now(); }, 1000);
    await storeModule.loadCommercialLogAPIData();
    await this.loadStreamDataAPIData();
  }
}
