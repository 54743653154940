




















































import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import { axiosConfig } from '@/api';
import axios from 'axios';
import { rootModule } from '@/store';
import { storeModule, storeNS } from '../store';

@Component
export default class extends Vue {
  @storeNS.State readonly event!: API.Events.GET | null;
  @storeNS.State readonly authorisedChannels!: API.AuthorisedChannels.Channels.GET[];
  alert = false;
  err = false;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }

  get applicableChannels(): API.AuthorisedChannels.Channels.GET[] {
    if (!this.selectedEvent) return this.authorisedChannels;
    return this.authorisedChannels.filter((c) => this.event?.channelIds.includes(c.id));
  }

  async run(length: API.Commercials.Start.POST['length']): Promise<void> {
    try {
      const { data } = await axios.post<{ data: API.Commercials.Logs.GET }>(
        this.selectedEvent
          ? `/commercials/start/${this.selectedEvent}`
          : '/commercials/start',
        {
          length,
          channelIds: !this.selectedEvent
            ? this.applicableChannels.map((c) => c.id)
            : undefined,
        } as API.Commercials.Start.POST,
        axiosConfig(),
      );
      storeModule.pushToCommercialLog(data.data);
    } catch (err) {
      this.err = true;
    }
    this.alert = true;
  }
}
